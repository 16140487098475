import { UpdatePassword } from './../modeles/update-password.model';
import { API_URL } from 'app/config/api.url';
import { LoginForm } from './../modeles/login-form.model';
import { Observable } from 'rxjs';
import { JwtResponse } from './../modeles/jwt-response.model';
import { USER_KEY } from './../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const optionRequete = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(user: LoginForm): Observable<JwtResponse> {
    return this.http.post(API_URL + "auth/login", user, { responseType: 'json' });
  }

  isAuth(): boolean {
    var user = new JwtResponse();
    user = this.getUser();
    if (user != null && user != '') {
      if (Object.keys(user).length == 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  setUser(jwtResponse) {
    sessionStorage.setItem(USER_KEY, JSON.stringify(jwtResponse));
  }

  getUser(): JwtResponse {
    var user = new JwtResponse()
    user = JSON.parse(sessionStorage.getItem(USER_KEY));
    return user;
  }

  getUserByUsername(username: string): Observable<any> {
    return this.http.get(API_URL + "users/username/" + username, { responseType: 'json' });
  }

  changePassword(id: number, updatePassword: UpdatePassword): Observable<any> {
    return this.http.post(API_URL + "users/update-password/" + id, updatePassword, { responseType: 'json' });
  }

  logout() {
    sessionStorage.removeItem(USER_KEY);
  }

  mapRoles(user: JwtResponse): string {
    return user.authorities.map(role => role.authority).join(" ")
  }
}
