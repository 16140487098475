import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService:AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler){
    if(this.authService.isAuth()){
      const token=this.authService.getUser().token;
      request=request.clone({
        headers: request.headers.set('Authorization', 'Bearer '+token)
      });
    }

    return next.handle(request);
  }
}

export const AuthInterceptorProviders=[
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}
];