export const ENTITE = {
    LAFARGE: 'LAFARGE',
    MAERSK: 'MAERSK',
    UBIPHARM: 'UBIPHARM',
    MSC: 'MSC',
    OFMG: 'OFMG',
    CMA: 'CMA',
    MEDLOG: 'MEDLOG',
    CKRYTERMINAL: 'CKRYTERMINAL',
    PAYCARD: 'PAYCARD',
    CREDIT_RURAL: 'CREDITRURAL',
    NSIA: 'NSIA',
    SUNU: 'SUNU',
    ITAXE: 'eTAX',
    FINADEV: 'FINADEV',
    SIGPC: 'SIGPC',
    EDG: 'EDG',
    VDC: 'VDC',
    BNB: 'BNB',
    SCHOOL_FEE: 'SCHOOL_FEE',
    CFU: 'CFU',
    VISA: 'CARTE-VISA',
    CONTRAVENTION: 'CONTRAVENTION',
    VIGNETTE: 'VIGNETTE',
    MOUNA: 'MOUNA',
    YAGAZ: 'YAGAZ',
    UGAR: 'UGAR'
};

export const USER_KEY = 'user';

export const ROLES = {
    TRANSACTION: "ROLE_TRANSACTION", //ROLE_TRANSACTION
    USER: "ROLE_USER",
    ADMIN: "ROLE_ADMIN",
    SUPER_ADMIN: "ROLE_SUPER_ADMIN"
};

declare interface RouteInfos {
    path: string;
    entite: string;
    roles: string[];
}

export const ROUTES: RouteInfos[] = [
    { path: '/paiement-ofmg', entite: ENTITE.OFMG, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/stock-bl-ofmg', entite: ENTITE.OFMG, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/paiement-yagaz', entite: ENTITE.LAFARGE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-ugar', entite: ENTITE.LAFARGE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-lafarge', entite: ENTITE.LAFARGE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-maersk', entite: ENTITE.MAERSK, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-ubipharm', entite: ENTITE.UBIPHARM, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-msc', entite: ENTITE.MSC, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-medlog', entite: ENTITE.MEDLOG, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-cma', entite: ENTITE.CMA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-ckryterminal', entite: ENTITE.CKRYTERMINAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-paycard', entite: ENTITE.PAYCARD, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-credit-rural', entite: ENTITE.CREDIT_RURAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-nsia', entite: ENTITE.NSIA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-sunu', entite: ENTITE.SUNU, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-itaxe', entite: ENTITE.ITAXE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-finadev', entite: ENTITE.FINADEV, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-sigpc', entite: ENTITE.SIGPC, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-vdc', entite: ENTITE.VDC, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-bnb', entite: ENTITE.BNB, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-cfu', entite: ENTITE.CFU, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-visa', entite: ENTITE.VISA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-contravention', entite: ENTITE.CONTRAVENTION, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-vignette', entite: ENTITE.VIGNETTE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/paiement-mouna', entite: ENTITE.MOUNA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION] },
    { path: '/facture-cfu', entite: ENTITE.CFU, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/stock-bl-maersk', entite: ENTITE.MAERSK, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/lafarge', entite: ENTITE.LAFARGE, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/identifiant-ubipharm', entite: ENTITE.UBIPHARM, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/facture-msc', entite: ENTITE.MSC, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/facture-medlog', entite: ENTITE.MEDLOG, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/facture-cma', entite: ENTITE.CMA, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/facture-ckryterminal', entite: ENTITE.CKRYTERMINAL, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/facture-vdc', entite: ENTITE.VDC, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/client-paycard', entite: ENTITE.PAYCARD, roles: [ROLES.USER, ROLES.ADMIN] },
    { path: '/client-nsia', entite: ENTITE.NSIA, roles: [ROLES.USER, ROLES.ADMIN] },
    {
        path: '/client-sunu', entite: ENTITE.SUNU
        , roles: [ROLES.USER, ROLES.ADMIN]
    },
    { path: '/user', entite: null, roles: [ROLES.ADMIN] },
];

export const MARCHANDS = {
    PAYCARD: 1
}