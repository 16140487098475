import { Location } from '@angular/common';
import { ROLES, ENTITE, ROUTES } from './../config/config';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authService:AuthService, private location:Location) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
    let url=state.url;
    let roles=this.authService.mapRoles(this.authService.getUser())

    if(roles.includes(ROLES.SUPER_ADMIN)){
      return true;
    }else {
      let routes: any[];
      
      if(roles.includes(ROLES.TRANSACTION)){
        routes = ROUTES.filter(route =>route.roles.filter(role=>roles.includes(role)).length>0)
      } else if (roles.includes(ROLES.CALLER)) {
        routes = ROUTES.filter(route => route.roles.filter(role => roles.includes(role)).length > 0)
      }else{
        routes = ROUTES.filter(route =>{
          return (!route.entite || roles.includes(route.entite)) && route.roles.filter(role=>roles.includes(role)).length>0
        })
      }
      
      if(routes.some(route=>route.path===url)){
        return true
      }else{
        this.location.back()
        return false
      }

    }
  }
}
