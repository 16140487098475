import { UpdatePassword } from './../../modeles/update-password.model';
import { Router } from '@angular/router';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import swal from 'sweetalert2';
import { BnNgIdleService } from 'bn-ng-idle';
import { ENTITE } from 'app/config/config';

declare var $:any;

@Component({
    // moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    username:string="";
    update:UpdatePassword=new UpdatePassword();
    confirm:string="";

    constructor(location: Location,  private element: ElementRef, 
        private authService:AuthService, private router:Router, private bnIdle:BnNgIdleService) {
      this.location = location;
          this.sidebarVisible = false;
    }

    ngOnInit(){
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
      this.username=this.authService.getUser().username;
      $('#changePassword').appendTo("body")
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }

      /* let routeUrl=this.router.url.toUpperCase()
      routeUrl=!routeUrl.includes("USER")?routeUrl.substring(routeUrl.lastIndexOf("-")+1):"UTILISATEURS"

      if(routeUrl==ENTITE.CMA) return "CMA-CGM"
      else if(routeUrl==ENTITE.CKRYTERMINAL) return "Conakry Terminal"
      else return routeUrl */

      return ROUTES.find(route=>route.path===this.router.url).title
    }

    logout(){
        this.authService.logout();
        this.bnIdle.stopTimer();
        this.router.navigate(['login'])
    }

    updatePassword(){
        if(this.isNotEmpty(this.update, this.confirm)){
            if(this.update.oldPassword!==this.update.newPassword){
                if(this.confirm===this.update.newPassword){
                    this.authService.getUserByUsername(this.authService.getUser().username)
                    .subscribe(userInfo=>{
                        this.authService.changePassword(userInfo.id, this.update)
                        .subscribe(()=>{
                            this.initUpdate();
                            swal.fire({
                                title: "Info",
                                type: "success",
                                text: "Changement effectué. Vous allez être deconnecté",
                                showConfirmButton: true
                            }).then(()=>{
                                $('#changePassword').modal('hide');
                                window.location.reload();
                                $('body #changePassword').empty();
                                this.logout()
                            });
                        },err=>{
                            this.initUpdate();
                            swal.fire({
                                title: "Erreur",
                                type: "error",
                                text: ""+err.error.message,
                                showConfirmButton: true
                            });
                        });
                    })
                }else{
                    //this.initUpdate();
                    swal.fire({
                        title: "Erreur",
                        type: "error",
                        text: "Erreur de confirmation du mot de passe",
                        showConfirmButton: true
                    });
                }
            }else{
                //this.initUpdate();
                swal.fire({
                    title: "Erreur",
                    type: "error",
                    text: "Erreur mots de passe identiques",
                    showConfirmButton: true
                });
            }  
        }else{
            swal.fire({
                title: "Erreur",
                type: "error",
                text: "Erreur -> certains champs sont vides",
                showConfirmButton: true
            });
        }
         
    }

    initUpdate(){
        this.update=new UpdatePassword();
        this.confirm="";
    }

    isNotEmpty(update:UpdatePassword, confirm:string):boolean{
       return update.oldPassword!=undefined && update.oldPassword!="" 
                && update.newPassword!=undefined && update.newPassword!="" 
                && confirm!=undefined && confirm!="";
    }

    showModal(){
        $('#changePassword').modal('show');
        /* if($("body").children('#changePassword').length>0){
            $('#changePassword').modal('show');
          }else{
            $('#changePassword').appendTo("body").modal('show');
          } */
    }

    ngAfterViewInit(){
        $("#update_user_password").click((event)=>{
          event.stopPropagation();
          //alert( "GO" ); 
          this.updatePassword();
        });
    }

    ngOnDestroy(){
        $('body #changePassword').empty()
    }
}
