import { ENTITE } from './../config/config';
import { AuthService } from './../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ROLES } from 'app/config/config';

declare const $: any;

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    entite: string;
    roles: string[];
}
export const ROUTES: RouteInfo[] = [
    {
        path: '/paiement-ofmg', title: 'Paiement OFMG', icon: 'pe-7s-home', class: '',
        entite: ENTITE.OFMG, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/stock-bl-ofmg', title: 'STOCKS BL OFMG', icon: 'pe-7s-home', class: '',
        entite: ENTITE.OFMG, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-lafarge', title: 'Paiement LAFARGE', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.LAFARGE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-maersk', title: 'Paiement MAERSK', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.MAERSK, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-ubipharm', title: 'Paiement UBIPHARM', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.UBIPHARM, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-msc', title: 'Paiement MSC', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.MSC, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-medlog', title: 'Paiement MEDLOG', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.MEDLOG, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-cma', title: 'Paiement CMA-CGM', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CMA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-ckryterminal', title: 'Paiement CONAKRY TERMINAL', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CKRYTERMINAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-paycard', title: 'Paiement PayCard', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.PAYCARD, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION, ROLES.CALLER]
    },
    {
        path: '/paiement-credit-rural', title: 'Paiement CREDIT RURAL DE GUINEE', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CREDIT_RURAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-edg', title: 'Paiement EDG', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CREDIT_RURAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION, ROLES.CALLER]
    },
    {
        path: '/paiement-seg', title: 'Paiement SEG', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CREDIT_RURAL, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION, ROLES.CALLER]
    },
    {
        path: '/paiement-nsia', title: 'Paiement NSIA', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.NSIA, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-sunu', title: 'Paiement SUNU', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.SUNU, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-itaxe', title: 'Paiement eTax', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.ITAXE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/paiement-yagaz', title: 'Paiement YA!GAZ', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.YAGAZ, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-ugar', title: 'Paiement UGAR ACTIVA', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.UGAR, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/stock-bl-maersk', title: 'STOCKS BL MAERSK', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.MAERSK, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-bnb', title: 'PAIEMENT BNB', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.BNB, roles: [ROLES.USER, ROLES.ADMIN, ROLES.CALLER]
    },
    {
        path: '/paiement-cfu', title: 'Paiement CFU', icon: 'pe-7s-cart', class: '',
        entite: ENTITE.CFU, roles: [ROLES.USER, ROLES.ADMIN, ROLES.TRANSACTION]
    },
    {
        path: '/identifiant-ubipharm', title: 'IDENTIFIANTS UBIPHARM', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.UBIPHARM, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-msc', title: 'FACTURES MSC', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.MSC, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-medlog', title: 'FACTURES MEDLOG', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.MEDLOG, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-cma',
        title: 'FACTURES CMA-CGM',
        icon: 'pe-7s-ticket',
        class: '',
        entite: ENTITE.CMA,
        roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-ckryterminal', title: 'FACTURES CONAKRY TERMINAL', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.CKRYTERMINAL, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/client-paycard', title: 'Clients PayCard', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.PAYCARD, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/client-nsia', title: 'CLIENT NSIA', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.NSIA, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/client-sunu', title: 'CLIENT SUNU', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.SUNU, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-finadev', title: 'PAIEMENT FINADEV', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.FINADEV, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-sigpc', title: 'PAIEMENT SIGPC', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.SIGPC, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-visa', title: 'PAIEMENT CARTE VISA', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.VISA, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/type-carte', title: 'TYPE CARTE VISA', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.VISA, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-contravention', title: 'PAIEMENT CONTRAVENTION', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.CONTRAVENTION, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-vignette', title: 'PAIEMENT VIGNETTE', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.VIGNETTE, roles: [ROLES.USER, ROLES.ADMIN, ROLES.CALLER]
    },
    {
        path: '/paiement-vdc', title: 'PAIEMENT VDC', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.VDC, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-mouna', title: 'PAIEMENT MOUNA', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.MOUNA, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-vdc', title: 'FACTURE VDC', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.VDC, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/facture-cfu', title: 'FACTURE CFU', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.CFU, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/paiement-school-fee', title: 'SCHOOL FEE', icon: 'pe-7s-ticket', class: '',
        entite: ENTITE.SCHOOL_FEE, roles: [ROLES.USER, ROLES.ADMIN]
    },
    {
        path: '/user', title: 'Utilisateurs', icon: 'pe-7s-user', class: '',
        entite: null, roles: [ROLES.ADMIN]
    }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    menuItems: any[];

    constructor(private authService: AuthService) { }

    ngOnInit() {
        //this.menuItems = ROUTES_SUPER_ADMIN.filter(menuItem => menuItem);
        this.loadSideBar();
    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    loadSideBar() {

        let roles = this.authService.mapRoles(this.authService.getUser())

        if (roles.includes(ROLES.SUPER_ADMIN)) {
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        } else if (roles.includes(ROLES.TRANSACTION)) {
            this.menuItems = ROUTES.filter(menuItem => menuItem.roles.filter(role => roles.includes(role)).length > 0);
        }

        else if (roles.includes(ROLES.CALLER)) {
            this.menuItems = ROUTES.filter(menuItem => menuItem.roles.filter(role => roles.includes(role)).length > 0);
        }
        else {
            this.menuItems = ROUTES.filter(menuItem => {
                return (!menuItem.entite || roles.includes(menuItem.entite)) && menuItem.roles.filter(role => roles.includes(role)).length > 0
            })
        }

    }
}

