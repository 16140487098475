// export const API_URL='http://localhost:8084/api/';  //Local Dev Environment.

//Url Dev
// export const API_URL = 'http://172.21.72.78:8181/partenaires-om-dev/api/';

//url Prod
  export const API_URL='https://partenairesom.orange-guinee.com/lafarge/api/'

//url Preprod
//export const API_URL='http://172.21.70.69:8080/partenaires-om-v2/api/';
//export const API_URL='http://10.173.84.119:8080/partenaires-om-v2/api/';





//PROD
//export const API_URL='https://partenairesom.orange-guinee.com/partenaires-om/api/';


//export const API_URL='http://10.173.84.83:8181/lafarge_backend-1.0/api/';

//Prod
//export const API_URL='http://10.173.84.83:8181/lafarge_backend-1.0/api/';

//export const API_URL='http://172.21.70.69:8080/partenaires-om-0.0.1/api/'; // URL TEST 


//export const API_URL='http://10.173.84.83:8181/partenaires-om-backend/api/';

//export const API_URL='http://10.173.84.83:8181/partenaires-om/api/';

